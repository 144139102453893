<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <delete-items-button v-if="$hasPermission($permissions.RemoveSales)" class="mt-2" :selection="selection" @deleted="resetTable" @reset=" selection.selected_item_ids = []"/>
            <basic-table ref="basicTable" :columns="columns" :data="formattedData" v-slot="props" @update-selection="updateSelection" @filter-update="filterUpdate">
                <div v-if="props.column.displayType === 4 && $hasPermission($permissions.RemoveSales)" class="d-flex justify-content-center">
                    <b-form-checkbox  v-model="selection.selected_item_ids" :value="props.row.id"></b-form-checkbox>
                </div>
                <div v-else-if="props.column.displayType === 1" class="d-flex justify-content-center">
                    <b-button v-if="$hasPermission($permissions.EditSales)" variant="warning" @click="openSaleEdit(sales[props.row.index])" class="mr-1">
                        <feather-icon icon="EditIcon"/>
                    </b-button>
                    <b-button v-if="$hasPermission($permissions.RemoveSales)" variant="danger" @click="removeSale(props.row.id)" class="mr-1">
                        <feather-icon icon="Trash2Icon"/>
                    </b-button>
                </div>
                <div v-else-if="props.column.displayType === 2" class="d-flex justify-content-center">
                    <table>
                        <tr v-for="(product, index) in props.row.items" :key="index" style="vertical-align: middle">
                            <td class="pb-50 pt-50">{{product.name}}</td>
                            <td class="pb-50 pt-50">{{product.quantity}}x</td>
                        </tr>
                    </table>
                </div>
                <div v-else-if="props.column.displayType === 3" class="d-flex justify-content-center">
                    <span>{{props.row.user_name}} {{props.row.user_last_name}}</span>
                </div>
                <span v-else class="d-flex justify-content-center">
                    {{ props.formattedRow[props.column.field] }}
                </span>

            </basic-table>
        </b-overlay>

        <b-modal title="Edit sale" v-model="editSaleModalActive" size="lg" no-close-on-backdrop>
            <template #default>
                <b-form-group>
                    <label>Outlets</label>
                    <v-select
                        v-model="editObject.outlet"
                        @input="editOutletName($event)"
                        label="name"
                        :options="outlets"
                    />
                </b-form-group>

                <b-form-group>
                    <label>Items</label>
                    <div>
                        <div v-for="(product, index) in editObject.items" :key="index">
                            <hr v-if="index !== 0">
                            <b-row>
                                <b-col cols="9" sm="7">
                                    <b-form-group>
                                        <label>Product</label>
                                        <v-select
                                            v-model="editObject.items[index].name"
                                            @input="editProductName($event, index)"
                                            label="name"
                                            :options="items"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="2" sm="5" align="right">
                                    <b-button variant="danger" @click="editObject.items.splice(index, 1)" class="mr-1 mt-2">
                                        <feather-icon icon="Trash2Icon"/>
                                    </b-button>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col sm="3">
                                    <b-form-group>
                                        <label>Quantity</label>
                                        <b-form-input :value="editObject.items[index].quantity" v-on:input="(value) => (Number(value) !== 0 || value !== '') ? changeQuantity(index, value) : Number(value)"/>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="3">
                                    <b-form-group>
                                        <label>Price without VAT</label>
                                        <b-form-input :value="displayPrice(editObject.items[index].price_without_vat)" v-on:input="(value) => (Number(value) !== 0 || value !== '') ? changePriceWithoutVAT(index, value) : Number(value)"/>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="3">
                                    <b-form-group>
                                        <label>VAT Value</label>
                                        <b-form-input :value="displayPrice(editObject.items[index].vat)" v-on:input="(value) => (Number(value) !== 0 || value !== '') ? changeVATValue(index, value) : Number(value)"/>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="3">
                                    <b-form-group>
                                        <label>Price with VAT</label>
                                        <b-form-input disabled :value="displayPrice(editObject.items[index].price_with_vat)" v-on:input="(value) => editObject.items[index].price_without_vat = value"/>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-form-group>
            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="editSaleModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="warning" @click="saveSale">
                    <feather-icon
                        icon="EditIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Save</span>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>

    import {BButton, BFormGroup, BFormInput, BModal, BOverlay, BRow, BCol, BFormCheckbox} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import vSelect from 'vue-select'
    import DeleteItemsButton from '@/views/components/DeleteItemsButton.vue'

    export default {
        components: {
            DeleteItemsButton,
            vSelect,
            BOverlay,
            BasicTable,
            BButton,
            BFormGroup,
            BFormInput,
            BModal,
            BRow,
            BCol,
            BFormCheckbox
        },
        props: {
            sales: {
                type: Array
            }
        },
        data() {
            return {
                dataLoaded: false,
                outlets: [],
                items: [],
                selection:{
                    selected_item_ids:[],
                    collection_name:''
                },
                columns: [
                    {
                        label: 'Select',
                        displayType: 4,
                        field: 'select',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    },
                    {
                        label: 'User',
                        displayType: 3,
                        field: 'user_name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Time',
                        displayType: 0,
                        field: 'time',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'hh:mm'
                        }
                    },
                    {
                        label: 'Date',
                        displayType: 0,
                        field: 'date',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'YYYY/MM/DD'
                        }
                    },
                    {
                        label: 'Outlet',
                        displayType: 0,
                        field: 'outlet',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'TIN',
                        displayType: 0,
                        field: 'tin',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Outlet Segment',
                        displayType: 0,
                        field: 'outlet_segment',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Vehicle name',
                        displayType: 0,
                        field: 'distribution_vehicle_name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Area',
                        displayType: 0,
                        field: 'area',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Territory',
                        displayType: 0,
                        field: 'territory',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'District',
                        displayType: 0,
                        field: 'district',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Price without VAT',
                        displayType: 0,
                        field: 'price_without_vat',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Vat',
                        displayType: 0,
                        field: 'vat',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Price with VAT',
                        displayType: 0,
                        field: 'price_with_vat',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Items',
                        displayType: 2,
                        field: 'items',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],
                editSaleModalActive: false,
                editObject: {
                    name: '',
                    type: '',
                    registration: '',
                    warehouses: []
                }
            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false
                const thisIns = this

                const outletsLoadPromise = this.$http.get('/api/management/v1/outlet')
                outletsLoadPromise.then(async function(response) {
                    thisIns.outlets = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const itemsLoadPromise = this.$http.get('/api/management/v1/product')
                itemsLoadPromise.then(function(response) {
                    thisIns.items = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([outletsLoadPromise, itemsLoadPromise])                    
                    .finally(function() {
                        thisIns.dataLoaded = true
                    })
            },
            openSaleEdit(object) {
                this.editObject = JSON.parse(JSON.stringify(object))
                this.editSaleModalActive = true
            },
            saveSale() {
                let totalPriceWithoutVat = 0
                let totalVat = 0
                let totalPriceWithVat = 0
                for (const item of this.editObject.items) {
                    totalPriceWithoutVat += item.price_without_vat
                    totalVat += item.vat
                    totalPriceWithVat += item.price_with_vat
                }
                this.editObject.price_without_vat = totalPriceWithoutVat
                this.editObject.vat = totalVat
                this.editObject.price_with_vat = totalPriceWithVat

                const thisIns = this

                const savePromise = this.$http.put(`/api/management/v1/sales/${  this.editObject.id}`, this.editObject)
                savePromise.then(function() {
                    thisIns.editSaleModalActive = false
                    thisIns.$printSuccess('Sale saved')
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.$emit('update-data')
                })
            },
            async removeSale(id) {

                try {

                    const confirmDelete = await this.$confirmDialog('Do you want to remove this sale?')
                    
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/management/v1/sales/${  id}`)
                    this.$printSuccess('Sale removed')
                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error)                    
                } finally {
                    this.loadData()
                }
            },
            displayPrice(val) {
                return (val / 100).toFixed(2)
            },
            changeQuantity(index, val) {
                const previousQuantity = this.editObject.items[index].quantity
                const previousPriceWithoutVat = this.editObject.items[index].price_without_vat
                this.editObject.items[index].price_without_vat = Number(previousPriceWithoutVat / previousQuantity) * Number(val)

                const vat = Number(this.editObject.items[index].vat)
                const vatPercentage = Number(Number(Number(Number(vat / previousPriceWithoutVat)) * 100).toFixed(2))
                this.editObject.items[index].vat = Number((Number(vatPercentage / 100) * this.editObject.items[index].price_without_vat).toFixed(0))
                this.editObject.items[index].price_with_vat = Number(this.editObject.items[index].price_without_vat + this.editObject.items[index].vat)

                this.editObject.items[index].quantity = Number(val)
            },
            changePriceWithoutVAT(index, val) {
                const previousPriceWithoutVat = this.editObject.items[index].price_without_vat
                const value = Number(Number(val * 100).toFixed(0))

                const vat = Number(this.editObject.items[index].vat)
                const vatPercentage = Number(Number(Number(Number(vat / previousPriceWithoutVat)) * 100).toFixed(2))
                this.editObject.items[index].vat = Number((Number(vatPercentage / 100) * value).toFixed(0))
                this.editObject.items[index].price_with_vat = Number(value + this.editObject.items[index].vat)

                this.editObject.items[index].price_without_vat = value
            },
            changeVATValue(index, val) {
                const value = Number((Number(val) * 100).toFixed(0))
                this.editObject.items[index].price_with_vat = Number(Number(this.editObject.items[index].price_without_vat) + value)

                this.editObject.items[index].vat = value
            },
            editProductName(item, index) {
                this.editObject.items[index].product_id = item.id
                this.editObject.items[index].name = item.name
            },
            editOutletName(item) {
                this.editObject.outlet_id = item.id
                this.editObject.outlet = item.name
            },
            resetTable() {
                this.$refs.basicTable.$refs.table.reset()
                this.$emit('reload')
            },
            updateSelection(e) {
                if (this.$refs.basicTable.$refs.table.paginated[0] && this.$refs.basicTable.$refs.table.processedRows[0] && e) {
                    let arr = []
                    const pagResults = this.$refs.basicTable.$refs.table.paginated[0].children
                    const filterResults = this.$refs.basicTable.$refs.table.processedRows[0].children

                    arr = pagResults.filter(element => filterResults.includes(element))
                    arr.forEach((item) => {
                        const el =  this.selection.selected_item_ids.includes(item.id)
                        if (!el)   this.selection.selected_item_ids.push(item.id)
                    })
                } else {
                    this.selection.selected_item_ids = []
                }
            },
            filterUpdate(e) {
                e.forEach((item) => {
                    const el =  this.selection.selected_item_ids.includes(item.id)
                    if (el)   this.selection.selected_item_ids.splice(item.id)
                })
                this.$store.dispatch('app/updateSelectAll', false)
            }
        },
        computed: {
            formattedData() {
                return this.sales.map((sale, index) => {
                    return {
                        index,
                        id: sale.id,
                        username: sale.username,
                        user_name: sale.user_name,
                        user_last_name: sale.user_last_name,
                        user_id: sale.user_id,
                        time: this.moment(sale.time).format('HH:mm'),
                        date: this.moment(sale.time).format('YYYY/MM/DD'),
                        outlet_id: sale.outlet_id,
                        outlet: sale.outlet,
                        outlet_segment: sale.outlet_segment,
                        tin: sale.tin,
                        items: sale.items,
                        distribution_vehicle_name: sale.distribution_vehicle_name,
                        distribution_vehicle_id: sale.distribution_vehicle_id,
                        area: sale.area,
                        territory: sale.territory,
                        district: sale.district,
                        vat: (sale.vat / 100).toFixed(2),
                        price_without_vat: (sale.price_without_vat / 100).toFixed(2),
                        price_with_vat: (sale.price_with_vat / 100).toFixed(2)
                    }
                })
            }
        },
        mounted() {
            this.selection.collection_name = 'sales'
            this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card>
                <b-row>
                    <b-col sm="4">
                        <label>Date from</label>
                        <b-form-datepicker v-model="dateFrom" @input="loadData"/>
                    </b-col>
                    <b-col sm="4">
                        <label>Date to</label>
                        <b-form-datepicker v-model="dateTo" @input="loadData"/>
                    </b-col>
                    <b-col sm="4" v-if="$hasPermission($permissions.ExportExcel)">
                        <label>Export</label>
                        <b-form-group>
                            <b-button @click="exportToExcel">Export to excel</b-button>
                        </b-form-group>
                    </b-col>
                </b-row>

                <SalesTable :sales="sales" @reload="loadData" @update-data="updateData"/>
            </b-card>
        </b-overlay>
    </div>
</template>
<script>

    import {BCard, BOverlay, BRow, BCol, BFormDatepicker, BButton, BFormGroup} from 'bootstrap-vue'
    import SalesTable from '@/views/components/SalesTable'
    import $permissions from '@/permissions'

    export default {
        computed: {
            $permissions() {
                return $permissions
            }
        }, 
        components: {
            BCard,
            BOverlay,
            BRow,
            BCol,
            BFormDatepicker,
            BButton,
            BFormGroup,
            SalesTable
        },
        data() {
            return {
                dataLoaded: false,
                sales: [],
                dateFrom: '',
                dateTo: ''
            }
        },
        methods: {
            exportToExcel() {
                const dateFrom = this.moment(this.dateFrom).startOf('day').utc().format()
                const dateTo = this.moment(this.dateTo).endOf('day').utc().format()
                window.open(`/api/management/v1/export/sales?&time.gte=${dateFrom}&&time.lte=${dateTo}`)
            },
            loadData() {
                this.dataLoaded = false
                const dateFrom = this.moment(this.dateFrom).startOf('day').utc().format()
                const dateTo = this.moment(this.dateTo).endOf('day').utc().format()
                const thisIns = this
                const loadPromise = this.$http.get(`/api/management/v1/sales?&time.gte=${dateFrom}&&time.lte=${dateTo}`)
                loadPromise.then(function(response) {
                    thisIns.sales = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            setDate() {
                this.dateFrom = this.moment().startOf('month').utc().format()
                this.dateTo = this.moment().endOf('month').utc().format()
            },
            updateData() {
                this.loadData()
            }
        },
        async mounted() {
            await this.setDate()
            await this.loadData()
        }
    }
</script>